import React, { FC, useEffect, useState } from 'react';
import { Container, getResultComponent } from './ResultList';

export interface StaticResultListProps {
  name: string;
  entity: string;
  selectAction: (selected: any) => void;
  defaultResults: any[];
}

export const StaticResultList: FC<StaticResultListProps> = ({ name, entity, selectAction, defaultResults }) => {
  const [results, setResults] = useState<any[]>();

  useEffect(() => {
    const getResults = () => name
      ? defaultResults.filter(item => item.name.toLowerCase().includes(name))
      : defaultResults;

    setResults(getResults());
  }, [name, defaultResults]);

  const Result = getResultComponent(entity);

  return (
    <Container>
      {results &&
        results.map((result) => <Result key={result._id} result={result} onSelect={selectAction}/>)
      }
    </Container>
  );
};
