import { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useI18n } from '#hooks/useI18n';
import { IdentifierDTO, Options, Regular, useModal, LinkIcon } from '@zazume/zzm-base';
import { Dropdown } from '#/components/dropdown/Dropdown';
import { DocumentUploadLinkModal } from '../documentUploadLinkModal/DocumentUploadLinkModal';
import { DocumentCategories, RelatedModelType } from '#/models';

const DropdownOption = styled.div(({ theme }) => css`
  padding: ${theme.spacing.sm};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.s2};
  cursor: pointer;
  svg {
    flex-shrink: 0;
  }
`);

interface DropdownMenuProps {
  relatedModel: RelatedModelType;
  relatedModelId: IdentifierDTO;
  category: DocumentCategories;
}

export const DropdownMenu: FC<DropdownMenuProps> = ({ relatedModel, relatedModelId, category }) => {
  const { t } = useI18n();
  const { open: openUploadLinksModal, RenderModal: RenderUploadLinksModal } = useModal({ modalSize: 'l' });

  return (<>
    <Dropdown header={<Options size={24} cursor="pointer" color="Gray600"/>} position="right" topValue={30}>
      <Dropdown.Item>
        <DropdownOption onClick={openUploadLinksModal}>
          <LinkIcon color="Gray600"/><Regular variant="S">{t('documents.manageLinks')}</Regular>
        </DropdownOption>
      </Dropdown.Item>
    </Dropdown>
    <RenderUploadLinksModal>
      <DocumentUploadLinkModal relatedModel={relatedModel} relatedModelId={relatedModelId} category={category}/>
    </RenderUploadLinksModal>
  </>
  );
};
