import React, { FC } from 'react';
import { useSearch } from '../../hooks';
import { useI18n } from '../../hooks/useI18n';
import { Container, getResultComponent, ResultCaption } from './ResultList';
import { IdentifierDTO } from '@zazume/zzm-base';

type Item = { _id: IdentifierDTO };

const filterIds = <T extends Item>(idsToExclude?: IdentifierDTO[]) =>
  (items: T[] = []): T[] => {
    if (!idsToExclude?.length) {
      return items;
    }
    return items.filter(item =>
      !idsToExclude.includes(item._id));
  };

export interface SuggestionListProps {
  entity: string;
  filter?: string
  suggestedResultsHint?: string;
  onSuggestedResults: any;
  selectAction: (selected: any) => void;
  idsToExclude?: IdentifierDTO[];
}

export const SuggestionList: FC<SuggestionListProps> = ({ entity, filter, selectAction, suggestedResultsHint, onSuggestedResults, idsToExclude }) => {
  const { t } = useI18n();

  const queryUniqueKey = `suggestedResults_${entity}${filter ? `_${filter}` : ''}`;
  const { data: suggestedResults, isLoading } = useSearch<Item[]>(queryUniqueKey, onSuggestedResults);

  const Result = getResultComponent(entity);

  const afterFilter = filterIds(idsToExclude)(suggestedResults);

  return (
    <Container>
      {suggestedResultsHint && <ResultCaption>{suggestedResultsHint}</ResultCaption>}
      {isLoading && <span>{t('general.loading')}</span>}
      {!isLoading && afterFilter && afterFilter.map(result =>
        <Result key={result._id} result={result} onSelect={selectAction}/>)}
    </Container>
  );
};
