import { API } from '#/lib/network/API';
import { IdentifierDTO } from '@zazume/zzm-base';
import { useEffect, useState } from 'react';
import { useCopilotChatIdStorage } from './useCopilotChatIdStorage';

type CopilotChat = {
  chatId: IdentifierDTO
  isLoading: false;
} | {
  chatId: undefined;
  isLoading: true;
};

const extractChatId = async (chatIdParam: IdentifierDTO | undefined,
  chatIdLocalStorage: IdentifierDTO | null,
  addChatIdQueryParam: (value: string) => void,
  addChatIdLocalStorage: (value: string) => void): Promise<IdentifierDTO> => {

  if (chatIdParam) {
    addChatIdLocalStorage(chatIdParam);
    return chatIdParam;
  }

  if (chatIdLocalStorage) {
    addChatIdQueryParam(chatIdLocalStorage);
    return chatIdLocalStorage;
  }

  const chatId = await API.copilot.createConversation();
  addChatIdLocalStorage(chatId);
  addChatIdQueryParam(chatId);
  return chatId;
};

export const useCopilotChat = (): CopilotChat => {
  const { addChatIdQueryParam, addChatIdLocalStorage, chatIdQueryParam, chatIdLocalStorage } = useCopilotChatIdStorage();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [chatId, setChatId] = useState<IdentifierDTO | undefined>(undefined);

  useEffect(() => {
    const setChat = async () => {
      const chatId = await extractChatId(chatIdQueryParam, chatIdLocalStorage, addChatIdQueryParam, addChatIdLocalStorage);
      setChatId(chatId);
      setIsLoading(false);
    };

    setChat();
  }, [chatIdQueryParam, chatIdLocalStorage, addChatIdQueryParam, addChatIdLocalStorage]);

  if (isLoading) {
    return {
      chatId: undefined,
      isLoading: true
    };
  }

  return {
    isLoading: false,
    chatId: chatId!
  };

};
