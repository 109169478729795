import { FCC } from '@zazume/zzm-base';
import React, { createContext, useContext, useState } from 'react';

const COPILOT_WIDGET_STATE = 'CopilotWidgetState';
const LOCAL_STORAGE_CHAT_ID_KEY = 'copilotChatId';

interface ContextValue {
  isOpen: boolean;
  handleIsOpen: () => void;
}

export const useCopilotContext = () => {
  const ctx = useContext(CopilotContext);
  if (!ctx) {
    throw new Error('CopilotContext must be used within CopilotProvider');
  }
  return ctx;
};

export const CopilotContext = createContext<ContextValue | null>(null);

interface ProviderProps {
}

export const CopilotProvider: FCC<ProviderProps> = ({ children }) => {
  const localStorageState = localStorage.getItem(COPILOT_WIDGET_STATE) === 'true';
  const [isOpen, setIsOpen] = useState<boolean>(localStorageState);
  const handleIsOpen = () => setIsOpen(prev => {
    localStorage.setItem(COPILOT_WIDGET_STATE, String(!prev));
    return !prev;
  });

  const ctx = {
    isOpen,
    handleIsOpen
  };

  return (
    <CopilotContext.Provider value={ctx}>
      {children}
    </CopilotContext.Provider>
  );
};
