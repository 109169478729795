import React, { FC } from 'react';
import { DropdownItemProps } from '../../../dropdown/atoms/Item';
import { IdentifierDTO, isZazumeUser, User } from '../../../../models';
import { ZazumeOrganizationOptions } from './components/ZazumeOrganizationOptions';
import { BaseOrganizationOptions } from './components/BaseOrganizationOptions';
import { Position } from '../../../dropdown/Dropdown';

export interface OrganizationOptionsProps extends DropdownItemProps {
  onSelect: (organizationId: IdentifierDTO) => void;
  header: any;
  user: User;
  position?: Position;
  topValue?: number;
}

export const OrganizationOptions: FC<OrganizationOptionsProps> = ({ onSelect, header, user, position, topValue }) => {

  if (isZazumeUser(user)) {
    return <ZazumeOrganizationOptions
      onSelect={onSelect}
      header={header}
      position={position}
      topValue={topValue}
    />;
  }

  if (!user.owner?.ownerAccountId) {
    return null;
  }

  return <BaseOrganizationOptions
    onSelect={onSelect}
    header={header}
    ownerAccountId={user.owner.ownerAccountId}
    position={position}
    topValue={topValue}
  />;
};
