import { IdentifierDTO } from '@zazume/zzm-base';
import { OwnerAccountReport } from './OwnerAccountReport';
import { Payout } from '../Payout';

export interface PayoutReport {
  hasErrors: boolean;
  hasWarnings: boolean;
  recipientType: 'tenant' | 'ownerAccount';
  hasTenantValidIban: boolean;
  hasTenantValidName: boolean;
  hasTenantValidAddress: boolean;
  isTenantValid: boolean;
  isPaymentPaid: boolean;
  withPaidExpense: boolean;
  isAmountPositive: boolean;
  isOwnershipDistributionValid: boolean;
  ownerAccountReports: Record<IdentifierDTO, OwnerAccountReport>;
}

interface PayoutsReport {
  hasErrors: boolean;
  reports: Record<IdentifierDTO, PayoutReport>;
}

interface InitiateBulkPaymentReportProps {
  hasErrors: boolean;
  hasWarnings: boolean;
  isBulkPaymentsPSD2Enabled: boolean;
  isAccountLinkedBankValid: boolean;
  isAccountLinkedCurrencyValid: boolean;
  isAccountLinkedIbanValid: boolean;
  isAccountLinkedValid: boolean;
  isBankNameValid: boolean;
  isBankValid: boolean;
  isOrganizationBillingDataValid: boolean;
  isOrganizationIbanValid: boolean;
  isOrganizationIdValid: boolean;
  isPayoutListValid: boolean;
  payoutsReport: PayoutsReport;
}

export class InitiateBulkPaymentReport {
  hasErrors: boolean;
  hasWarnings: boolean;
  isBulkPaymentsPSD2Enabled: boolean;
  isAccountLinkedBankValid: boolean;
  isAccountLinkedCurrencyValid: boolean;
  isAccountLinkedIbanValid: boolean;
  isAccountLinkedValid: boolean;
  isBankNameValid: boolean;
  isBankValid: boolean;
  isOrganizationBillingDataValid: boolean;
  isOrganizationIbanValid: boolean;
  isOrganizationIdValid: boolean;
  isPayoutListValid: boolean;
  payoutsReport: PayoutsReport;

  constructor({
    hasErrors,
    hasWarnings,
    isBulkPaymentsPSD2Enabled,
    isAccountLinkedBankValid,
    isAccountLinkedCurrencyValid,
    isAccountLinkedIbanValid,
    isAccountLinkedValid,
    isBankNameValid,
    isBankValid,
    isOrganizationBillingDataValid,
    isOrganizationIbanValid,
    isOrganizationIdValid,
    isPayoutListValid,
    payoutsReport
  }: InitiateBulkPaymentReportProps) {
    this.hasErrors = hasErrors;
    this.hasWarnings = hasWarnings;
    this.isBulkPaymentsPSD2Enabled = isBulkPaymentsPSD2Enabled;
    this.isAccountLinkedBankValid = isAccountLinkedBankValid;
    this.isAccountLinkedCurrencyValid = isAccountLinkedCurrencyValid;
    this.isAccountLinkedIbanValid = isAccountLinkedIbanValid;
    this.isAccountLinkedValid = isAccountLinkedValid;
    this.isBankNameValid = isBankNameValid;
    this.isBankValid = isBankValid;
    this.isOrganizationBillingDataValid = isOrganizationBillingDataValid;
    this.isOrganizationIbanValid = isOrganizationIbanValid;
    this.isOrganizationIdValid = isOrganizationIdValid;
    this.isPayoutListValid = isPayoutListValid;
    this.payoutsReport = payoutsReport;
  }

  getAllPayoutsReports(): PayoutReport[] {
    return Object.values(this.payoutsReport.reports);
  }

  getPayoutReport(payoutId: IdentifierDTO): PayoutReport {
    return this.payoutsReport.reports[payoutId];
  }

  hasErrorCategory(payoutId: IdentifierDTO, category: PayoutReportErrorCategory): boolean {
    const report = this.getPayoutReport(payoutId);
    switch (category) {
      case 'generalErrors':
        return report?.hasErrors === true;
      case 'invalidIban':
        return Object.values(report?.ownerAccountReports || {}).some(ownerReport => (ownerReport as OwnerAccountReport)?.hasUniqueOwnerAccountIban === false);
      case 'unpaidIncome':
        return report?.isPaymentPaid === false;
      case 'paidExpense':
        return report?.withPaidExpense === true;
    }
  }

  ownerAccountReportHasError(ownerReport: OwnerAccountReport): boolean {
    return ownerReport.hasErrors;
  }

  hasSomeOwnerAccountError(): boolean {
    return this.getAllPayoutsReports().some(report => Object.values(report.ownerAccountReports).some(this.ownerAccountReportHasError));
  }

  hasGeneralErrors(): boolean {
    return !this.isAccountLinkedBankValid
      || !this.isAccountLinkedCurrencyValid
      || !this.isAccountLinkedIbanValid
      || !this.isAccountLinkedValid
      || !this.isBankNameValid
      || !this.isBankValid
      || !this.isOrganizationBillingDataValid
      || !this.isOrganizationIbanValid
      || !this.isOrganizationIdValid
      || !this.isPayoutListValid;
  }

  hasSomeErrorOnSelectedPayouts(payouts: Payout[]): boolean {
    return this.hasGeneralErrors()
      || payouts.some(payout => this.getPayoutReport(payout._id).hasErrors === true);
  }

  hasSomeErrorOrWarningOnSelectedPayouts(payouts: Payout[]): boolean {
    return this.hasSomeErrorOnSelectedPayouts(payouts)
      || payouts.some(payout => this.getPayoutReport(payout._id).hasWarnings === true);
  }
}

export const PayoutReportErrorCategories = <const>['generalErrors', 'invalidIban', 'unpaidIncome', 'paidExpense'];
export type PayoutReportErrorCategory = typeof PayoutReportErrorCategories[number];
