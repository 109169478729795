import { UseAPIResult } from '#/hooks';
import React, { FC, ReactNode } from 'react';

interface AsyncComponentProps<T, R> {
  loadingComponent?: JSX.Element;
  errorComponent?: JSX.Element;
  emptyStateComponent?: JSX.Element;
  children: (data: T, apiResult: R) => ReactNode | ReactNode[];
  apiResult: R;
  optionalData?: boolean;
}

const Loading: FC = () => {
  return <>...</>;
};

const Error: FC = () => {
  return <>...</>;
};

type TInput = object | object[];

export const AsyncComponent = <T extends TInput = any, R extends UseAPIResult<T> = UseAPIResult<T>>({
  apiResult,
  loadingComponent,
  errorComponent,
  children,
  emptyStateComponent,
  optionalData
}: AsyncComponentProps<T, R>): any => {
  if (apiResult.isLoading) {
    return loadingComponent || <Loading/>;
  }

  if (apiResult.isError) {
    return errorComponent || <Error/>;
  }

  if (!apiResult.data && !optionalData) {
    return emptyStateComponent || <Loading/>;
  }

  const toRender = children(apiResult.data!, apiResult);
  if (Array.isArray(toRender)) {
    return <>{toRender}</>;
  }

  return toRender;
};
