import i18n from './index';

/**
 * @deprecated use Locale
 */
export type LanguageTypes = 'es' | 'en';

/**
 * @deprecated use useI18n.language
 */
export const getCurrentUILanguage = (): LanguageTypes => {
  const lang = i18n.language;
  switch (lang.toLowerCase()) {
    case 'es':
      return 'es';
    default:
      return 'en';
  }
};
