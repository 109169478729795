import { SearchToken } from '#/models/Search';
import { API } from '#lib/network/API';
import { FCC } from '@zazume/zzm-base';
import algoliasearch, { SearchClient } from 'algoliasearch';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from './AuthProvider';

export interface SearchContextResult extends SearchToken {
  searchClient?: SearchClient;
}

const initialSearchToken: Readonly<SearchToken> = {
  token: '',
  indexPrefix: '',
  appId: ''
};

const SearchContext = React.createContext<SearchContextResult>(initialSearchToken);

export const SearchProvider: FCC<any> = ({ children }) => {
  const { user } = useAuth();
  const [token, setToken] = useState<SearchToken>(initialSearchToken);
  const [searchClient, setSearchClient] = useState<SearchClient>();

  useEffect(() => {
    let abortController;

    const load = async () => {
      abortController = new AbortController();
      const newToken = await API.search.getToken(abortController.signal)();
      setToken(newToken);
      setSearchClient(algoliasearch(newToken.appId, newToken.token));
    };

    if (user) {
      load().catch(error => {
        if (abortController && !abortController.signal.aborted) {
          console.error(error);
        }
      });
    }

    return () => {
      if (abortController && !abortController.signal.aborted) {
        abortController.abort();
      }
    };
  }, [user]);

  const value = useMemo<SearchContextResult>(() => ({
    token: token.token,
    indexPrefix: token.indexPrefix,
    searchClient,
    appId: token.appId
  }), [searchClient, token]);

  return <SearchContext.Provider value={value}>
    {children}
  </SearchContext.Provider>;
};

export const useSearchContext = (): SearchContextResult => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }
  return context;
};
