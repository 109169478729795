import { Flex, getIcon, IconType, Regular, Semibold, ZZMColor } from '@zazume/zzm-base';
import React, { FC, ReactNode } from 'react';
import { useDetailFieldsContext } from './atoms/FieldsSectionContext';

export interface FieldValueProps {
  title: string;
  value?: string | null | ReactNode;
  emptyValue?: string;
  isDefaultHidden?: boolean;
  icon?: IconType;
  color?: ZZMColor;
}

type Component = FC<FieldValueProps> & {
  Skeleton: typeof Skeleton;
};

export const FieldValue: Component = ({ title, value, emptyValue = '-', isDefaultHidden, icon, color = 'Gray600' }) => {
  const { areSectionsHidden } = useDetailFieldsContext();

  if (isDefaultHidden && areSectionsHidden) {
    return null;
  }

  const Icon = icon && getIcon(icon);

  return <Flex direction="column" gap="s2">
    <Regular color="Gray400" variant="S">{title}</Regular>
    <Flex gap="xs">
      {Icon && value && <Icon size={20} color="Gray400"/>}
      <Semibold variant="S" color={color}>{value || emptyValue}</Semibold>
    </Flex>
  </Flex>;
};

const Skeleton: FC<{ title: string }> = ({ title }) =>
  <FieldValue title={title} value={<Semibold.Skeleton width={title.length * 8}/>}/>;

FieldValue.Skeleton = Skeleton;
