import { IdentifierDTO } from '@zazume/zzm-base';
import { EndpointBuilder, doGet, doPost, doPostFileUpload, doPut, doPutFileUpload, toPaginatedURL } from '../../helpers';
import { NonPayment } from '#/models/nonPayment/NonPayment';
import { PaginationOptions } from '#/hooks';
import { CreateDocumentRequest } from '../documents/requests/CreateDocumentRequest';
import { UpdateDocumentRequest } from '../documents/requests/UpdateDocumentRequest';

export const nonPayments = {
  getOne: (nonPaymentId: IdentifierDTO) =>
    (): Promise<NonPayment> => doGet(`/dashboard/non-payment/${nonPaymentId}`),
  create: (body: object): Promise<IdentifierDTO> =>
    doPost('/dashboard/non-payment/create', body),
  update: (id: IdentifierDTO) => (body: object): Promise<IdentifierDTO> =>
    doPut(`/dashboard/non-payment/update/${id}`, body),
  updateStatus: (id: IdentifierDTO) => (body: object): Promise<IdentifierDTO> =>
    doPut(`/dashboard/non-payment/status/update/${id}`, body),
  getPaginated: (params: EndpointBuilder) => (pagination: PaginationOptions) => () =>
    doGet<NonPayment[]>(toPaginatedURL(`/dashboard/non-payment${params.url}${params.queryParams}`, pagination)),
  createDocument: (nonPaymentId: IdentifierDTO) =>
    (request: CreateDocumentRequest) =>
      doPostFileUpload<void>(`/dashboard/non-payment/documents/${nonPaymentId}/`, request.build()),
  updateDocument: (nonPaymentId: IdentifierDTO, documentId: IdentifierDTO) => (request: UpdateDocumentRequest) =>
    doPutFileUpload<void>(`/dashboard/non-payment/documents/${nonPaymentId}/${documentId}`, request.build())
};
