import { useI18n } from '#hooks/useI18n';
import { FileObject, IdentifierDTO } from '@zazume/zzm-base';
import { PaginationOptions, useNotifications } from '#/hooks';
import { Storage } from '#/utils/Storage';
import { Config } from '../Config';
import { getCurrentUILanguage } from '../i18n/utils';
import { BadRequestError } from './errors/BadRequestError';
import { UnauthorizedError } from './errors/UnauthorizedError';
import { UnexpectedError } from './errors/UnexpectedError';

type ParserFunction = (json) => object;
const noParser: ParserFunction = json => json;
export const parse = (parser) => json => json ? parser(json) : undefined;
const parseList = parser => raw => raw.map(parser);
export const parserDataList = parser => parseList(parser);
const toJSON = response => {
  try {
    if (response.status === 204) {
      return;
    }
    return response.json();
  } catch (e) {
    return {};
  }
};

interface DoRequestOptions {
  signal?: AbortSignal;
  expectNotFound?: boolean;
}

export const doGet = async <T>(url: string, parser?, body?: object, options?: DoRequestOptions): Promise<T> =>
  doRequest<T>(url, 'GET', body, parser, options);

export const doPost = async <T>(url: string, body: object = {}, parser?): Promise<T> =>
  doRequest<T>(url, 'POST', body, parser);

export const doPatch = async <T>(url: string, body: object = {}, parser?): Promise<T> =>
  doRequest<T>(url, 'PATCH', body, parser);

export const doPut = async <T>(url: string, body: object = {}, parser?): Promise<T> =>
  doRequest<T>(url, 'PUT', body, parser);

export const doDelete = async <T>(url: string, parser?): Promise<T> =>
  doRequest<T>(url, 'DELETE', parser);

export const doPatchFileUpload = async <T>(url: string, body: FormData, parser?): Promise<T> =>
  doRequestUpload<T>(url, 'PATCH', body, parser);

export const doPutFileUpload = async <T>(url: string, body: FormData, parser?): Promise<T> =>
  doRequestUpload<T>(url, 'PUT', body, parser);

export const doPostFileUpload = async <T>(url: string, body: FormData, parser?): Promise<T> =>
  doRequestUpload<T>(url, 'POST', body, parser);

const getBodyFromResponse = async (response) => {
  try {
    return await response.json();
  } catch (e) {
    return 'No body';
  }
};

export const handleErrors = (expectNotFound?: boolean) => async (response) => {
  if (!response.ok) {
    const body = await getBodyFromResponse(response);
    if (response.status >= 500) {
      throw UnexpectedError.from('Server error', body);
    }

    if (response.status === 401) {
      throw new UnauthorizedError();
    }

    if (response.status === 404 && expectNotFound) {
      return;
    }

    if (response.status >= 400) {
      // ControlledErrors v2
      if (body.code) {
        const { code, message, ...rest } = body;
        throw new BadRequestError(message, body.code, rest);
      }

      throw new BadRequestError(body.error, body.errorCode, body.structuredData);
    }

    throw UnexpectedError.from('Unexpected error', body);
  }
  return response;
};

const getZazumeHeaders = (): HeadersInit => {
  const result: any = {
    'zzm-api-version': 'v2'
  };

  const currentOrganization = localStorage.getItem('currentOrganizationToken');
  if (currentOrganization) {
    result.organization = currentOrganization;
  }

  const authToken = Storage.getAuthToken();
  if (authToken) {
    result.Authorization = `Bearer ${authToken}`;
  }

  return result;
};

const getHeaders = (): HeadersInit => ({
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'zazume-lang': getCurrentUILanguage(),
  ...getZazumeHeaders()
});

const doRequest = async <T>(endpoint: string,
  method: string,
  body?: object,
  parser = noParser,
  options: DoRequestOptions = {}): Promise<T> => {

  const request: RequestInit = {
    method,
    credentials: 'include',
    headers: getHeaders(),
    signal: options.signal
  };

  if (body) {
    request.body = JSON.stringify(body);
  }

  const url = Config.API_URL + endpoint;
  return new Promise((resolve, reject) => {
    fetch(url, request)
      .then(handleErrors(options.expectNotFound))
      .then(toJSON)
      .then(parse(parser))
      .then(resolve)
      .catch(err => reject(err));
  });
};

const doRequestUpload = async <T>(endpoint: string,
  method: string,
  body: FormData,
  parser = noParser): Promise<T> => {

  const request: RequestInit = {
    method,
    credentials: 'include',
    headers: {
      ...getZazumeHeaders()
    }
  };

  if (body) {
    request.body = body;
  }

  const url = Config.API_URL + endpoint;
  return new Promise((resolve, reject) => {
    fetch(url, request)
      .then(handleErrors())
      .then(toJSON)
      .then(parse(parser))
      .then(resolve)
      .catch(err => reject(err));
  });
};

export const toPaginatedURL = (url: string, paginationOptions: PaginationOptions): string => {
  if (!url) {
    return '';
  }

  const params: string[] = [];
  if (paginationOptions.page && !url.includes('page=')) {
    params.push(`page=${paginationOptions.page}`);
  }

  if (!url.includes('size=')) {
    params.push(`size=${paginationOptions.size}`);
  }

  if (paginationOptions.last && !url.includes('last=')) {
    params.push(`last=${paginationOptions.last}`);
  }

  if (paginationOptions.sort && !url.includes('sort=')) {
    params.push(`sort=${paginationOptions.sort}`);
  }
  if (paginationOptions.dir && !url.includes('dir=')) {
    params.push(`dir=${paginationOptions.dir}`);
  }

  if (url.includes('?')) {
    return `${url}&${params.join('&')}`;
  }

  return `${url}?${params.join('&')}`;
};

export const isAClientError = (error) =>
  error.code === 400;

export const isUnauthorizedError = (error) =>
  error.code === 401;

export const isNotFoundError = (error) =>
  error.code === 40;

// TODO move to a better place
export enum ErrorCode {
  USER_HAS_NO_VALID_PHONE_NUMBER = 11,
  USER_EMAIL_OR_PASSWORD_DONT_MATCH = 12,
  USER_ALREADY_EXISTS = 13,
  USER_UNAUTHORIZED = 14,
  USER_NOT_FOUND = 15,
  USER_WRONG_ROLE = 16,
  USER_TOKEN_EXPIRED = 17,
  USER_HAS_NO_VALID_ID_CARD = 20,

  PURCHASE_IS_PENDING = 21,
  PURCHASE_IS_ALREADY_CANCELLED = 22,
  PURCHASE_CANCEL_REQUEST_ALREADY_SENT = 23,
  PURCHASE_ALREADY_COMPLETED = 24,

  CANCELLATION_POLICY_ALREADY_REGISTERED = 30,
  CANCELLATION_POLICY_NOT_FOUND = 31,

  TASKS_NO_FOUND_WITH_RELATIONSHIP = 41,

  INVALID_FIELD = 50,
  INVALID_ID = 51,
  INVALID_DATE = 52,
  INVALID_PASSWORD = 53,
  MISSING_FIELD = 54,
  INVALID_ADDRESS = 55,

  ORGANIZATION_COUNTRY_NOT_SUPPORTED = 61,
  ORGANIZATION_ALREADY_EXISTS = 61,

  UNIT_ALREADY_EXISTS = 71,
  BUILDING_HAS_UNITS = 74,
  TENANT_ALREADY_ASSIGNED = 75,
  WORKSPACE_HAS_TENANTS = 76,
  WORKSPACE_HAS_UNITS = 77,
  UNIT_WITHOUT_OWNER_ACCOUNT = 85,
  UNIT_WITHOUT_PLACE_ADDRESS = 86,
  UNIT_WITHOUT_SUPPLIES = 87,
  UNIT_WITHOUT_PRESCORING = 88,

  SERVICE_NOT_ENABLED = 82,

  GLOBAL_NOT_FOUND = 91,

  CONTRACT_NOT_FOUND = 303,

  ONBOARDING_USER_EXISTS_WITH_SAME_UNIT = 502,
  VIEWING_IS_OVERLAPPED_WITH_EXISTING_ONE = 527,

  EXTERNAL_SERVICE_FAILED = 530,

  PAYMENT_ALREADY_COMPLETED_OR_DECLINED = 601,
  PAYMENT_ALREADY_PAID_OR_PARTIALLY_PAID = 605,
  CANNOT_CREATE_DIRECT_DEBIT = 606,
  NO_RELATED_SEPA_FOUND = 607,
  DUPLICATED_DIRECT_DEBIT_DOCUMENT = 608,
  PAYMENT_NO_RECONCILABLE = 609,
  NO_SIGNED_SEPA_FOUND = 610,
  WRONG_SEPA_STRUCTURE = 611,
  TENANT_IBAN_MISSING = 612,
  MULTIPLE_PAYMENTS_LINKED_TO_BANK_TRANSACTION = 618,
  EXPENSE_LINKED_TO_RECURRENT_EXPENSE = 620,
  EXPENSE_LINKED_TO_PAID_INCOME = 621,
  EXPENSE_LINKED_TO_NON_PAID_INCOME = 622,

  ALREADY_EXISTS = 409,

  NO_COMPARABLES_FOUND = 412,
  NO_AVM_FOUND = 413,

  TENANT_IS_IN_ACTIVE_APPLICATION = 801,
  TENANT_IS_LIVING_IN_UNIT = 802,

  INVOICE_NOT_FOUND = 1000,
  CANNOT_CREATE_INVOICE = 1001,
  INVOICE_ALREADY_GENERATED = 1002,
  MOVEMENT_WITHOUT_TAXES = 1003,
  MOVEMENT_NOT_PAID = 1004,
  MOVEMENT_TYPE_INVALID = 1005,
  MOVEMENT_CREDITOR_INVALID = 1006,
  NO_OWNER_ACCOUNT = 1007,
  NO_BILLING_DATA = 1008,
  NO_OWNER_ADDRESS = 1009,
  NO_CLIENT_ADDRESS = 1010,
  DOCUMENT_CREATE_ERROR = 1011,
  NO_INVOICE_SERIES = 1012,
  NO_TENANT_ADDRESS_OR_IDNUMBER = 1013,
  NO_OWNER_BILLING_DATA = 1014,

  EXTERNAL_PROVIDER_ERROR = 3000,

  MESSAGING_NOT_FOUND = 2300,

  UNIT_WITHOUT_PRESCORING_WHEN_IS_PUBLISHED = 20011,

  KEY_DELIVERY_DATE_OVERLAPS_AGENT_VIEWING = 40001
}

/**
 * @deprecated use src/hooks/useAsyncMethod instead
 */
export const useNetworkExceptions = () => {
  const { t } = useI18n();
  const { showNotification } = useNotifications();

  const handleNetworkExceptions = (error, values = {}) => {
    const { errorCode } = error;

    if (error instanceof TypeError) {
      return showNotification(t('errorMessages.clientError'), { type: 'error' });
    }

    if (!errorCode && !navigator.onLine) {
      return showNotification(t('errorMessages.network'), { type: 'error' });
    }
    return showNotification(t(`errorCodes.${errorCode}` as any, values), { type: 'error' });
  };

  return {
    handleNetworkExceptions
  };
};

/**
 * @deprecated use the string[] format directly
 * @param params
 */
export const generateQueryUniqueKey = (params: any[]) =>
  params.filter(param => param ?? null).join('_');

export interface FiltersI {
  [key: string]: string | undefined;
}

export class EndpointBuilder {
  url: string = '';
  queryParams?: string;

  static build(workspace?: IdentifierDTO, organization?: IdentifierDTO, filters?: FiltersI): EndpointBuilder {
    let url = '';
    let queryParams = '';
    let urlParams = new URLSearchParams();

    if (workspace) {
      url += `/organization/${organization}/workspace/${workspace}`;
    } else if (!workspace && organization) {
      url += `/organization/${organization}`;
    }

    if (filters) {
      Object.keys(filters).forEach((key) => {
        filters[key]
          ? urlParams.set(key, filters[key]!)
          : urlParams.delete(key);
      });
      if (urlParams.toString().length > 0) {
        queryParams = `?${urlParams.toString()}`;
      }
    }

    return {
      url,
      queryParams
    };
  }

  static buildQueryParams(filters?: FiltersI): EndpointBuilder {
    let url = '';
    let queryParams = '';
    let urlParams = new URLSearchParams();

    if (filters) {
      Object.keys(filters).forEach((key) => {
        filters[key]
          ? urlParams.set(key, filters[key]!)
          : urlParams.delete(key);
      });
      if (urlParams.toString().length > 0) {
        queryParams = `?${urlParams.toString()}`;
      }
    }

    return {
      url,
      queryParams
    };
  }

  /**
   * Build a single query param in object format, eg: &user[name]=Carlos&user[surnames]=Miranda
   */
  static buildObjectQueryParam(paramName: string, filters?: FiltersI): EndpointBuilder {
    let url = '';
    let queryParams = '';
    let urlParams = new URLSearchParams();

    if (filters) {
      Object.keys(filters).forEach((key) => {
        filters[key]
          ? urlParams.set(`${paramName}[${key}]=${filters[key]}`, filters[key]!)
          : urlParams.delete(key);
      });
      if (urlParams.toString().length > 0) {
        queryParams = `&${urlParams.toString()}`;
      }
    }

    return {
      url,
      queryParams
    };
  }
}

export interface MultiPartBody {
  [key: string]: any;
}

export const generateMultipartFormData = (fileObject?: FileObject, data?: MultiPartBody): FormData => {
  const newFormData = new FormData();

  if (data) {
    newFormData.append('data', JSON.stringify(data));
  }

  if (!fileObject) {
    return newFormData;
  }

  if (!Array.isArray(fileObject.file)) {
    newFormData.append(fileObject.reference, fileObject.file, fileObject.file.name);
  } else {
    for (let file of fileObject.file) {
      newFormData.append(fileObject.reference, file);
    }
  }

  return newFormData;
};
