import { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Delete, Maintenance, Options, Pencil, Regular, Shadows, Tabs, useModal } from '@zazume/zzm-base';
import { formatDate } from '@zazume/zzm-utils';
import { Dropdown } from '../../../../../components/dropdown/Dropdown';
import { Can } from '../../../../../components/roles/Can';
import { useDelete, useNotifications } from '../../../../../hooks';
import { DocumentsTab } from './DocumentsTab';
import { useI18n } from '../../../../../hooks/useI18n';
import { isAClientError, useNetworkExceptions } from '../../../../../lib/network/helpers';
import { API } from '../../../../../lib/network/API';
import { GenericDeleteConfirmationModal } from '../../../../../components/modal/GenericDeleteConfirmationModal';
import { useURLTabs } from '../../../../../hooks/router/useURLTabs';
import { PageDetailTitle } from '../../../../../components/pages/detail/PageDetailTitle';
import { Provider } from '../../../../../models';
import { ProvidersKeys } from '../../../../../hooks/api/providers/Providers.keys';
import { AboutTab } from './AboutTab';
import { EditProviderModal } from '../modals/EditProvider.modal';
import { useNavigate } from 'react-router';
import { Private } from '../../../../../containers/router/routes/Private';

const Container = styled.div(({ theme }) => css`
  width: 100%;
  padding: ${theme.spacing.s10};
  box-shadow: ${Shadows.SHADOW_3};
  background: ${theme.colors.White};
  border-radius: ${theme.radius.xl};

  ${theme.mq.desktop} {
    padding: ${theme.spacing.s13} ${theme.spacing.s16};
  }
`);

const DropdownOption = styled.div(({ theme }) => css`
  padding: ${theme.spacing.sm};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.s2};
  cursor: pointer;
`);

interface ProviderDetailContentProps {
  provider: Provider;
}

export const ProviderDetailContent: FC<ProviderDetailContentProps> = ({ provider }) => {
  const { t } = useI18n();
  const { handleNetworkExceptions } = useNetworkExceptions();
  const { showNotification } = useNotifications();
  const navigate = useNavigate();

  const { open: openEditModal, RenderModal: RenderEditModal } = useModal({ modalSize: 'fullscreen' });
  const { open: showRemoveModal, RenderModal: RenderRemoveModal, close: hideRemoveModal } = useModal({ modalSize: 's', withMobileFullscreen: false });
  const urlTabsProps = useURLTabs();

  const queryKey = ProvidersKeys.byId(provider._id);
  const { mutate: remove } = useDelete(queryKey, API.providers.delete, { isOptimistic: false });

  const handleOnConfirmRemoveModal = async () => {
    try {
      await remove(provider._id);
      showNotification(t('providers.modals.remove.successMessage'));
      navigate(Private.PROVIDERS.route);
    } catch (error) {
      if (isAClientError(error)) {
        return handleNetworkExceptions(error);
      }
    } finally {
      hideRemoveModal();
    }
  };

  return (
    <Container>
      <PageDetailTitle
        icon={<Maintenance color="Gray600" size={32}/>}
        title={provider.name}
        rightContent={
          <Can hasRoles={['buildingManager', 'admin', 'ownerManager']}>
            <Regular variant="S" color="Gray400">{t('providers.common.addedOn')} {formatDate(provider.createdAt, 'DD/MM/YYYY')}</Regular>
            <Dropdown header={<Options size={24} cursor="pointer" color="Gray600"/>} position="right" topValue={30}>
              <Dropdown.Item>
                <Can hasRoles={['buildingManager', 'admin', 'ownerManager']}>
                  <DropdownOption onClick={openEditModal}>
                    <Pencil size={20} color="Gray500"/>
                    {t('providers.table.options.edit')}
                  </DropdownOption>
                </Can>
              </Dropdown.Item>
              <Dropdown.Item>
                <DropdownOption onClick={showRemoveModal}>
                  <Delete size={20} color="Red"/>
                  <Regular color="Red" variant="S">{t('general.remove')}</Regular>
                </DropdownOption>
              </Dropdown.Item>
            </Dropdown>
          </Can>
        }
      />
      <Tabs label={t('ownerAccount.detail.title')} {...urlTabsProps}>
        <Tabs.Tab key="about" title={t('general.about')}>
          <AboutTab provider={provider}/>
        </Tabs.Tab>
        <Tabs.Tab key="documents" title={t('general.docs')}>
          <DocumentsTab providerId={provider._id}/>
        </Tabs.Tab>
      </Tabs>
      <RenderEditModal>
        <EditProviderModal provider={provider} queryKey={queryKey}/>
      </RenderEditModal>
      <RenderRemoveModal>
        <GenericDeleteConfirmationModal
          title={t('providers.modals.remove.title')}
          description={t('providers.modals.remove.description')}
          onCancel={hideRemoveModal}
          onConfirm={handleOnConfirmRemoveModal}
        />
      </RenderRemoveModal>
    </Container>
  );
};
