import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Dropdown, DropdownProps, Position } from '../../../../dropdown/Dropdown';
import { IdentifierDTO, Organization } from '../../../../../models';
import { Check } from '@zazume/zzm-base';
import { useAuth } from '../../../../../contexts/AuthProvider';
import { css } from '@emotion/react';

const MenuItem = styled<any>(Dropdown.Item)(({ theme, isSelected }) => css`
  padding: ${theme.spacing.s6} ${theme.spacing.s8};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${isSelected && `color: ${theme.colors.Primary};`}
  ${isSelected && `background: ${theme.colors.PrimaryLight4};`}

  :hover {
    background: ${theme.colors.PrimaryLight4};
  }
`);

interface MenuListProps {
  header: DropdownProps['header'];
  organizations: Organization[];
  onSelect: (organizationId: IdentifierDTO) => void;
  position?: Position;
  topValue?: number;
}

export const OrganizationsMenuList: FC<MenuListProps> = ({ organizations, header, onSelect, position = 'left', topValue = 60 }) => {
  const { organization: currentOrganization } = useAuth();
  const handleOnSelect = (organization: Organization) =>
    () =>
      onSelect(organization._id);

  return <Dropdown header={header} position={position} leftValue={0} topValue={topValue}>
    {organizations.map(organization => {
      const isSelected = currentOrganization?._id === organization._id;
      return <MenuItem onClick={handleOnSelect(organization)} key={organization._id} isSelected={isSelected}>
        {organization.name} {isSelected && <Check size={16} color="Primary"/>}
      </MenuItem>;
    })}
  </Dropdown>;
};
