import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { IconType, IdentifierDTO, Link, ZazumeModal } from '@zazume/zzm-base';
import { SuggestionList } from './SuggestionList';
import { StaticResultList } from './StaticResultList';
import { useCurrentOrganization } from '../../contexts/AuthProvider';

import { filterExcludeObjectIds, fromIndexNameToAlgolia, IndexName } from '../../utils/algolia/algoliaHelper';
import { useSearchContext } from '../../contexts/SearchProvider';
import { Input } from '../forms';
import { AlgoliaResultList } from './AlgoliaResultList';
import { css } from '@emotion/react';

const Container = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`;

const Divider = styled.div(({ theme }) => css`
  height: 1px;
  background-color: ${theme.colors.Gray200};
  margin: 16px 0;
`);

const ActionButtonSection = styled.div(({ theme }) => css`
  padding-top: ${theme.spacing.s9};
`);

/**
 *
 * @param idsToExclude
 * @param filters Deprecated use idsToExclude instead. Only for back compatibility
 */
const toAlgoliaFilter = (idsToExclude?: IdentifierDTO[], filters?: string): string | undefined => {
  // Back compatibility remove when filters is not used anymore
  if (filters) {
    return filters;
  }

  if (idsToExclude) {
    return filterExcludeObjectIds(idsToExclude);
  }

  return undefined;
};

export interface AlgoliaSearcherProps {
  title?: string;
  placeholder?: string;
  entity: string;
  suggestedResultsHint?: string;
  onSuggestedResults?: any;
  onSelect: (selected: any) => void;
  hide: () => void;
  defaultResults?: any[];
  indexName: IndexName;
  /**
   * @deprecated use IdsToExclude instead
   */
  filters?: string;
  idsToExclude?: IdentifierDTO[];
  actionButtonText?: string;
  onClickActionButton?: () => void;
  actionButtonIcon?: IconType;
  actionButtonEnabled?: boolean;
}

export const AlgoliaSearcher: FC<AlgoliaSearcherProps> = ({
  title,
  placeholder,
  entity,
  suggestedResultsHint,
  onSuggestedResults,
  onSelect,
  hide,
  defaultResults,
  indexName,
  idsToExclude,
  filters,
  actionButtonText,
  onClickActionButton,
  actionButtonIcon,
  actionButtonEnabled = false
}) => {

  const [name, setName] = useState<string>('');

  const organizationId = useCurrentOrganization();
  const { searchClient, indexPrefix } = useSearchContext();
  const index = searchClient?.initIndex(fromIndexNameToAlgolia(indexPrefix, organizationId)(indexName));

  const [typingTimeout, setTypingTimeout] = useState<any>(null);
  const [searchResults, setSearchResults] = useState<any[]>([]);

  useEffect(() => {
    return () => typingTimeout && clearTimeout(typingTimeout);
  });

  const startTimeout = (value: string) => {
    const toFilter = toAlgoliaFilter(idsToExclude, filters);
    return setTimeout(async () => {
      value !== name && setName(value);
      index && index.search(value, { hitsPerPage: 3, filters: toFilter }).then(response => setSearchResults(response.hits));
      setTypingTimeout(null);
    }, 350);
  };

  const inputHandler = (value: string) => {
    typingTimeout && clearTimeout(typingTimeout);
    setTypingTimeout(startTimeout(value));
  };

  return (
    <ZazumeModal title={title}>
      <Container>
        <Input iconLeft="magnifier" onChange={inputHandler} placeholder={placeholder}/>
        <Divider/>
        {!defaultResults && name ?
          <AlgoliaResultList indexName={indexName} selectAction={onSelect} results={searchResults}/> :
          <SuggestionList selectAction={onSelect} suggestedResultsHint={suggestedResultsHint} onSuggestedResults={onSuggestedResults} idsToExclude={idsToExclude} entity={entity}/>}
        {defaultResults && <StaticResultList name={name} defaultResults={defaultResults} selectAction={onSelect} entity={entity}/>}
        {actionButtonEnabled && <ActionButtonSection>
          <Link variant="danger" onClick={onClickActionButton} leftIcon={actionButtonIcon}>
            {actionButtonText}
          </Link>
        </ActionButtonSection>}
      </Container>
    </ZazumeModal>
  );
};
