import React from 'react';
import styled from '@emotion/styled';
import { Colors, FCC, Fonts } from '@zazume/zzm-base';

const Container = styled.li`
  text-decoration: none;
  display: block;
  max-height: 248px;
  font-family: ${Fonts.BASE};
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0;
  color: ${Colors.FONT_3};
  text-align: start;

  &:hover {
      background-color: ${Colors.BACKGROUND_9};
    }
`;

export interface DropdownItemProps {
  onClick?: (...args) => void;
  id?: string;
}

export type DropdownItem = FCC<DropdownItemProps>;

export const Item: DropdownItem = props => {
  return <Container id={props.id} {...props}/>;
};
