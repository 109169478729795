import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, useEffect, useRef, useState } from 'react';
import { Bubble } from './Bubble';
import { Message } from '../../CopilotChat';
import { LoadingBubble } from './LoadingBubble';
import { IdentifierDTO } from '@zazume/zzm-base';
import { useGet } from '#/hooks';
import { API } from '#/lib/network/API';
import { CopilotChatKeys } from '#/hooks/api/copilot/Copilot.keys';
import { useUpdate } from '#/lib/reactQuery/hooks/useUpdate';
import { Footer } from '../footer/Footer';
import { IntroBody } from './IntroBody';

const Container = styled.div(({ theme }) => css`
  padding: ${theme.spacing.s24} ${theme.spacing.s7};
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  gap: ${theme.spacing.s5};

  ${theme.mq.md} {
    padding: ${theme.spacing.s24} ${theme.spacing.s12};
  }
`);

interface Props {
  conversationId: IdentifierDTO;
  isWidget: boolean;
  onCreateNewChat: () => void;
  openChatList: () => void;
  onClose?: () => void;
}

export const ChatLayer: FC<Props> = ({ conversationId, isWidget, onCreateNewChat, openChatList, onClose }) => {
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);
  const [lastUserMessage, setLastUserMessage] = useState<Message | undefined>(undefined);

  const messagesEndRef = useRef<any>(null);

  const scrollToBottom = () =>
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });

  const { data: messages, isLoading } = useGet<Message[]>(CopilotChatKeys.getMessages(conversationId), API.copilot.getConversationMessages(conversationId));
  const { mutate } = useUpdate<Message, { message: string }>(CopilotChatKeys.getMessages(conversationId), API.copilot.sendMessage(conversationId));

  useEffect(() => {
    messages?.length && scrollToBottom();
  }, [messages, isSendingMessage]);

  if (isLoading) {
    return null;
  }

  const onSendMessage = async (message: string) => {
    try {
      setLastUserMessage({ _id: 'temp', message, type: 'user', visibleForFinalUser: true, createdAt: new Date().toISOString() });
      setIsSendingMessage(true);
      await mutate({ message });
      setIsSendingMessage(false);
      setLastUserMessage(undefined);
    } catch (e) {
      console.error(e);
    }
  };

  const reversedMessages = messages && [...messages].reverse();

  const showConversation = (reversedMessages && reversedMessages.length > 0) || lastUserMessage;

  return <>
    {showConversation ? <Container>
      <div ref={messagesEndRef}/>
      {isSendingMessage && <LoadingBubble/>}
      {lastUserMessage && <Bubble key={lastUserMessage._id} message={lastUserMessage} conversationId={conversationId}/>}
      {reversedMessages && reversedMessages.map((message) =>
        (<Bubble key={message._id} message={message} conversationId={conversationId}/>))}
    </Container> : <IntroBody/>}
    <Footer
      onSendMessage={onSendMessage}
      isWidget={isWidget}
      isSendingMessage={isSendingMessage}
    />
  </>;
};
